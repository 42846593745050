<template>
  <sdCards title="Client Product Attributes">
    <template #button>
      <sdButton class="btn-signin" type="primary" @click="addNew" :disabled="isSaving">+ Add New Attribute</sdButton>
    </template>
    <FileCardWrap>
      <a-row :gutter="15">
        <a-col :xs="24">
          <AttributesTable />
        </a-col>
      </a-row>
    </FileCardWrap>
  </sdCards>

  <a-modal
    v-model:visible="visibleEditModal"
    :title="modalTitle"
    @ok="onSave"
    width="80%"
    :dialog-style="{ top: '5px' }"
  >
    <BasicFormWrapper>
      <LocationFormWrapper>
        <a-form name="multi-form" layout="horizontal">
          <a-row :gutter="30">
            <a-col :lg="12" :md="12" :xs="24" class="mb-25">
              <a-form-item label="Attribute Name">
                <a-input v-model:value="attribute.name" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :xs="24" class="mb-25">
              <a-form-item label="Attribute Code">
                <a-input v-model:value="attribute.code" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="30">
            <a-col :md="12" :xs="24" class="mb-25">
              <a-form-item label="Group">
                <a-select v-model:value="attribute.group" style="width: 100%">
                  <a-select-option value="">please select</a-select-option>
                  <a-select-option v-for="client in clients" :key="client.key" :value="client.key">{{
                    client.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-col :md="24" :xs="24" class="mb-25">
                <a-checkbox v-model:checked="attribute.used_as_product_option"> Used As Product Option </a-checkbox>
              </a-col>
              <a-col :md="24" :xs="24" class="mb-25">
                <a-checkbox v-model:checked="attribute.variant_product_field"> Variant Product Field </a-checkbox>
              </a-col>
              <a-col :md="24" :xs="24" class="mb-25">
                <a-checkbox v-model:checked="attribute.used_for_filter"> Used For Filter </a-checkbox>
              </a-col>
            </a-col>
            <a-col :md="12" :xs="24" class="mb-25">
              <a-form-item label="Details">
                <a-textarea v-model:value="attribute.details" />
              </a-form-item>
            </a-col>
            <a-col :md="12" :xs="24" class="mb-25">
              <a-form-item label="Filtering Options">
                <a-row>
                  <a-col :md="8" :xs="24" class="mb-25">
                    <a-checkbox v-model:checked="attribute.equal_filter"> Equal Option </a-checkbox>
                  </a-col>
                  <a-col :md="8" :xs="24" class="mb-25">
                    <a-checkbox v-model:checked="attribute.contain_filter"> Contain Option </a-checkbox>
                  </a-col>
                  <a-col :md="8" :xs="24" class="mb-25">
                    <a-checkbox v-model:checked="attribute.blank_filter"> Blank Option </a-checkbox>
                  </a-col>
                </a-row>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </LocationFormWrapper>
    </BasicFormWrapper>
  </a-modal>
</template>
<script>
import { FileCardWrap, LocationFormWrapper } from './style';
import AttributesTable from './components/AttributesTable.vue';
import { computed, ref, watch } from 'vue';
import { BasicFormWrapper } from '@/view/styled';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  name: 'ClientLocation',
  components: { FileCardWrap, AttributesTable, BasicFormWrapper, LocationFormWrapper },
  setup() {
    const { state, commit, dispatch } = useStore();
    const clients = [
      { key: 1, name: 'Product Information' },
      { key: 2, name: 'Pricing' },
      { key: 3, name: 'Shipping' },
      { key: 4, name: 'Options' },
      { key: 5, name: 'Categorisation' },
      { key: 6, name: 'Availability' },
      { key: 7, name: 'Media' },
      { key: 8, name: 'Custom Attributes' },
      { key: 9, name: 'SEO' }
    ];
    const { params } = useRoute();
    console.log(params.id, 'id');

    const visibleEditModal = computed({
      get() {
        return state.clients.visibleAttributesEditModal;
      },
      set(value) {
        commit('toggleAttributeEditModal', value);
      },
    });
    const attribute = ref(() => {});
    const modalTitle = computed(() => {
      if (attribute.value.id > 0) {
        return `Update Attribute`;
      } else {
        return `Register a new Attribute`;
      }
    });

    const addNew = () => {
      commit('initAttribute');
      commit('toggleAttributeEditModal', true);
    };
    const onSave = async () => {
      commit('setAttribute', attribute.value);
      commit('toggleAttributeEditModal', false);
      if (attribute.value.id > 0) {
        await dispatch('updateAttribute', { id: attribute.value.id });
      } else {
        await dispatch('createAttribute');
      }
    };

    watch(
      () => state.clients.visibleAttributesEditModal,
      (val) => {
        if (val === true) {
          attribute.value = state.clients.attribute;
        } else {
          commit('initAttribute');
          if (params.id !== 'new') {
            dispatch('fetchClient', {
              id: params.id,
            });
          }
        }
      },
    );

    return {
      addNew,
      visibleEditModal,
      modalTitle,
      onSave,
      attribute,
      clients,
    };
  },
};
</script>
